import { all, fork, takeEvery, call, put } from "redux-saga/effects"
import * as actionTypes from '../constants/index'
import axios from 'axios'
import CategorySaga from "./category"
import TranslationsSaga from "./translation"
import * as actions from '../actions'
import {
	loaded, loading, showConfirmationMsg,
	deleteCategoryReducer,
	getCategoriesDataReducer,
	getCategoriesReducer, getRolesRed,
	getStatuesRed, getTasksRed, getUserRed, getMsgRed, getPageRed, loadings, getMsg
} from '../actions'
import { API } from '../../utility/API'
import {REGIONS_URL, RESTAURANT_URL} from "../constants/index"

const getLanguages = () => {
	return API.get("https://antiksef.online/resturant_staging/category_content_service/public/api/v1/languages/index")
}
const getLanguage = () => {
	return API.get(actionTypes.RESTAURANT_URL + actionTypes.SHOW_LANG_URL)
}

export function* getLanguagesSaga(action) {
	try {
		yield put(loading())
		const response = yield call(getLanguages)
		const payload = { languages: response.data }
		yield put(actions.getLanguagesReducer(payload))
		yield put(loaded())
	} catch (error) {
		yield put(showConfirmationMsg(
			{
				showConfirmMsg: "true",
				msgConfirmTitle: 'Failed load Languages',
				msgConfirmBody: 'connetion Error',
				colorConfirmBtn: 'danger',
				txtConfirmBtn: 'try again'
			}
		))
		yield put(loaded())
	}
}
export function* watchGetLanguages() {
	yield takeEvery(actionTypes.GET_Languages_SAGA, getLanguagesSaga)
}

export function* getUsers(action) {
	try {
		yield put(loading())
		const response = yield API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/users`)

		yield put(getUserRed(response.data))
		yield put(loaded())
	} catch (error) {
		console.error(error)
		yield put(loaded())
	}
}
export function* watchGetUsers() {
	yield takeEvery("USER_SAGA", getUsers)
}
export function* getMsgs(action) {
	try {
		yield put(loading())
		yield put(loadings())
		const response = yield API.get(`${process.env.REACT_APP_BACKEND_CHAT_URL}/api/v1/tasks/${action.payload}/messages`)
		yield put(getMsgRed(response.data.data, response.data.next_page_url, response.data.prev_page_url))
		yield put(loaded())
	} catch (error) {
		console.error(error)
		yield put(loaded())
	}
}
export function* watchgetMsgs() {
	yield takeEvery("MSGS_SAGA", getMsgs)
}
export function* getMsgsPage(action) {
	try {
		yield put(loading())
		yield put(loadings())
		const response = yield API.get(`${action.page}`)
		yield put(getPageRed(response.data.data, response.data.next_page_url, response.data.prev_page_url))
		yield put(loaded())
	} catch (error) {
		console.error(error)
		yield put(loaded())
	}
}
export function* watchgetMsgsPage() {
	yield takeEvery("MSGS_SAGA_PAGE", getMsgsPage)
}

export function* send(action) {
	try {
		yield put(loading())
		yield put(loadings())
		const responsed = yield API.post(`${process.env.REACT_APP_BACKEND_CHAT_URL}/api/v1/channel_members/add_admin`, JSON.stringify({ channel_id: action.payload.channel_id }))
		const response = yield API.post(`${process.env.REACT_APP_BACKEND_CHAT_URL}/api/v1/messages/send`, JSON.stringify({
			task_id: action.payload.task_id,
			channel_id: parseInt(action.payload.channel_id),
			content: action.payload.data,
			message_type: "TextMessage"
		}))
		yield put(getMsg(action.payload.task_id))
		yield put(loaded())
	} catch (error) {
		console.error(error)
		yield put(loaded())
	}
}
export function* watchsend() {
	yield takeEvery("MSGS_SAGA_SEND", send)
}

export function* Assign(action) {
	try {
		yield put(loading())
		const response = yield API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/roles`, JSON.stringify({ role_id: action.r, category_id: action.c }))
		yield put(loaded())
	} catch (error) {
		console.error(error)
		yield put(loaded())
	}
}
export function* watchAssign() {
	yield takeEvery("ASSIGN_SAGA", Assign)
}
export function* getRoles(action) {
	try {
		yield put(loading())
		const response = yield API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/roles`)
		yield put(getRolesRed(response.data))
		yield put(loaded())
	} catch (error) {
		console.error(error)
		yield put(loaded())
	}
}
export function* watchGetRoles() {
	yield takeEvery("ROLES_SAGA", getRoles)
}
export function* getTasks(action) {
	try {
		yield put(loading())
		const response = yield API.get(`${process.env.REACT_APP_HELPDESK_BACKEND_BASE_URL}/api/v1/tasks`)
		yield put(getTasksRed(response.data.original.data))
		yield put(loaded())
	} catch (error) {
		console.error(error)
		yield put(loaded())
	}
}
export function* watchGetTasks() {
	yield takeEvery("TASKS_SAGA", getTasks)
}
export function* getStatues(action) {
	try {
		yield put(loading())
		const response = yield API.get(`${process.env.REACT_APP_HELPDESK_BACKEND_BASE_URL}/api/v1/task_statuses`)

		yield put(getStatuesRed(response.data.original.data))

		yield put(loaded())
	} catch (error) {
		console.error(error)
		yield put(loaded())
	}
}
export function* watchGetStatues() {
	yield takeEvery("STATUES_SAGA", getStatues)
}
export function* getLanguageSaga(action) {
	try {
		yield put(loading())
		const response = yield call(getLanguage)
		const payload = { lang: response.data }
		yield put(actions.getLanguageReducer(payload))
		yield put(loaded())
	} catch (error) {
		if (error.message) {
			yield put(loaded())
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: (error.name),
					msgConfirmBody: (error.message),
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'try again'
				}))
		} else {
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: 'Failed load Translations',
					msgConfirmBody: 'connetion Error',
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'try again'
				}
			))
			yield put(loaded())
		}
	}
}
export function* watchGetLanguage() {
	yield takeEvery(actionTypes.GET_Language_SAGA, getLanguageSaga)
}
function* addLanguage(action) {
	try {
		yield put(loading())
		const response = yield API.post(`${actionTypes.RESTAURANT_URL}languages/create`, JSON.stringify(action.payload), {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		if (!response.data.isSuccessful) {
			let res = ""
			if (!response.data.message) { res = "error" } else {
				Object.values(response.data.message).map((value, index) => {
					res = `${res + index} : ${value}`
				})
			}
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: ("error"),
					msgConfirmBody: (res),
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'reload page'
				}))
		} else {
			const response2 = yield call(getLanguages)
			const payload = { languages: response2.data }
			yield put(actions.getLanguagesReducer(payload))
		}
		yield put(loaded())
	} catch (error) {
		if (error.message) {
			yield put(loaded())
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: (error.name),
					msgConfirmBody: (error.message),
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'try again'
				}))
		} else {
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: 'Failed load Translations',
					msgConfirmBody: 'connetion Error',
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'try again'
				}
			))
			yield put(loaded())
		}
	}
}
function* watchAddLanguage() {
	yield takeEvery(actionTypes.ADD_Languages_SAGA, addLanguage)
}
function* updateLanguage(action) {
	try {
		yield put(loading())
		const response = yield API.post(actionTypes.RESTAURANT_URL + actionTypes.UPDATE_LANGUAGES_URL, JSON.stringify(action.payload), {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		if (!response.isSuccessful) {
			let res = ""
			if (!response.message) { res = "error" } else {
				Object.values(response.message).map((value, index) => {
					res = `${res + index} : ${value}`
				})
			}
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: ("error"),
					msgConfirmBody: (res),
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'reload page'
				}))
		} else {
			const response2 = yield call(getLanguages)
			const payload = { languages: response2.data }
			yield put(actions.getLanguagesReducer(payload))
		}
		yield put(loaded())
	} catch (error) {
		if (error.message) {
			yield put(loaded())
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: (error.name),
					msgConfirmBody: (error.message),
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'try again'
				}))
		} else {
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: 'Failed load Translations',
					msgConfirmBody: 'connetion Error',
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'try again'
				}
			))
			yield put(loaded())
		}
	}
}
function* watchUpdateLanguage() {
	yield takeEvery(actionTypes.UPDATE_Languages_SAGA, updateLanguage)
}
function* deleteLanguage(action) {
	try {
		yield put(loading())
		const response = yield API.post(actionTypes.RESTAURANT_URL + actionTypes.DELETE_LANGUAGES_URL, JSON.stringify(action.id), {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		if (!response.data.isSuccessful) {
			let res = ""
			if (!response.data.message) { res = "error" } else {
				Object.values(response.data.message).map((value, index) => {
					res = `${res + index} : ${value}`
				})
			}
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: ("error"),
					msgConfirmBody: (res),
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'reload page'
				}))
		} else {
			const response2 = yield call(getLanguages)
			const payload = { languages: response2.data }
			yield put(actions.getLanguagesReducer(payload))
		}
		yield put(loaded())
	} catch (error) {
		if (error.message) {
			yield put(loaded())
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: (error.name),
					msgConfirmBody: (error.message),
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'try again'
				}))
		} else {
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: 'Failed load Translations',
					msgConfirmBody: 'connetion Error',
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'try again'
				}
			))
			yield put(loaded())
		}
	}
}

function* watchDeleteLanguage() {
	yield takeEvery(actionTypes.DELETE_Languages_SAGA, deleteLanguage)
}

function* getCategories(actions) {
	try {
		yield put(loading())
		const response = actions.pagelink ? yield API.get(actions.pagelink) : yield API.get(actionTypes.RESTAURANT_URL + actionTypes.GET_CATEGORY_URL)

		yield put(getCategoriesReducer(response.data))
		yield put(loaded())
	} catch (e) {
		yield put(loaded())
	}
}

function* watchGetCategory() {
	yield takeEvery(actionTypes.GET_CATEGORIES_SAGA, getCategories)
}
function* addCategory(action) {
	try {
		yield put(loading())
		console.table('add category')
		const response = yield API.post(actionTypes.RESTAURANT_URL + actionTypes.ADD_CATEGORY_URL, JSON.stringify(action.payload), {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		console.table(response.data)
		const response1 = yield API.get(actionTypes.RESTAURANT_URL + actionTypes.GET_CATEGORY_URL)

		yield put(getCategoriesReducer(response1.data))

		yield put(loaded())
	} catch (e) {
		//alert('sorry bro');
		yield put(loaded())
	}
}
function* watchAddCategory() {
	yield takeEvery(actionTypes.ADD_CATEGORY_SAGA, addCategory)
}

function* getCategoriesData() {
	try {
		const response1 = yield API.get(actionTypes.RESTAURANT_URL + actionTypes.GET_LANGUAGES_URL)
		// const response2 = yield API.get(actionTypes.RESTAURANT_URL + actionTypes.GET_REGIONS_URL)


		const data = {
			languages: response1.data.languages
			// regions: response2.data.regions
		}

		//console.table(data);
		yield put(getCategoriesDataReducer(data))
	} catch (e) { }
}
function* watchGetCategoriesData() {
	yield takeEvery(actionTypes.GET_CATEGORIES_DATA_SAGA, getCategoriesData)
}

function* deleteCategory(action) {
	try {
		yield put(loading())
		const response = yield API.post(actionTypes.RESTAURANT_URL + actionTypes.DELETE_CATEGORY_URL, JSON.stringify(action.id), {
			headers: {
				'Content-Type': 'application/json'
			}
		})

		console.table(response)

		//yield put( getCategoriesReducer(response.data));
		yield put(deleteCategoryReducer(action.id))
		yield put(loaded())
	} catch (e) {
		yield put(loaded())
	}
}

function* watchDeleteCategory() {
	yield takeEvery(actionTypes.DELETE_CATEGORY_SAGA, deleteCategory)
}

function* updateCategory(action) {
	try {
		yield put(loading())
		console.table(action.category)
		const response = yield API.post(actionTypes.RESTAURANT_URL + actionTypes.UPDATE_CATEGORY_URL, JSON.stringify(action.category), {
			headers: {
				'Content-Type': 'application/json'
			}
		})

		console.table(response.data)
		const response1 = yield API.get(actionTypes.RESTAURANT_URL + actionTypes.GET_CATEGORY_URL)
		yield put(getCategoriesReducer(response1.data))
		//yield put( getCategoriesReducer(response.data));
		yield put(loaded())
	} catch (e) {
		yield put(loaded())
	}
}

function* watchUpdateCategory() {
	yield takeEvery(actionTypes.UPDATE_CATEGORY_SAGA, updateCategory)
}

const page = 0
const getRegion = (page1) => {
	if (page1 === 0) {
		return API.get(`https://antiksef.online/resturant_staging/bos_service/public${actionTypes.REGIONS_URL}`)
	} else {
		return API.get(`https://antiksef.online/resturant_staging/bos_service/public${actionTypes.REGIONS_URL}?page=${page1}`)
	}
}
const getRegionTypes = () => {
	return API.get(`https://antiksef.online/resturant_staging/bos_service/public${actionTypes.REGIONS_Type_URL}`)
}
export function* getProjectRegion(action) {
	try {
		yield put(loading())
		const response = yield call(getRegion, action.page)
		const response1 = yield call(getRegionTypes)
		const response2 = yield call(getLanguages)

		const payload = { rdata: response.data, rtype: response1.data, languages: response2.data }
		console.log(payload, response.data)
		yield put(actions.getProjectRegionReducer(payload))
		yield put(loaded())
	} catch (error) {
		if (error.message) {
			yield put(loaded())
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: (error.name),
					msgConfirmBody: (error.message),
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'try again'
				}))
		} else {
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: 'Failed load Reagions',
					msgConfirmBody: 'connetion Error',
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'try again'
				}
			))
			yield put(loaded())
		}
	}
}
export function* watchGetProjectRegion() {
	yield takeEvery(actionTypes.GET_PROJECT_REGION_SAGA, getProjectRegion)
}
export function* getNextProjectRegion(action) {
	try {
		yield put(loading())
		let response
		console.log(action.url)
		const a = `${RESTAURANT_URL}${"/api/v1/regions/sub_regions/"}${action.url}`
		if (action.url.length < 6) response = yield API.get(a)
		else response = yield API.get(`${action.url}`)
		const response1 = yield call(getRegionTypes)
		const response2 = yield call(getLanguages)
		const payload = { rdata: response.data, rtype: response1.data, languages: response2.data }
		yield put(actions.getProjectRegionReducer(payload))
		yield put(loaded())
	} catch (error) {
		if (error.message) {
			yield put(loaded())
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: (error.name),
					msgConfirmBody: (error.message),
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'try again'
				}))
		} else {
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: 'Failed load Reagions',
					msgConfirmBody: 'connetion Error',
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'try again'
				}
			))
			yield put(loaded())
		}
	}
}
export function* watchGetNextProjectRegion() {
	yield takeEvery(actionTypes.GET_NEXT_PROJECT_REGION_SAGA, getNextProjectRegion)
}
function* addRegion(action) {
	try {
		yield put(loading())
		console.log(action)
		const response = yield API.post(`${actionTypes.RESTAURANT_URL}/api/v1/regions/create`, JSON.stringify(action.payload), {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		if (!response.isSuccessful) {
			let res = ""
			if (!response.data.message) { res = "error" } else {
				Object.values(response.data.message).map((value, index) => {
					res = `${res + index} : ${value}`
				})
			}
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: ("error"),
					msgConfirmBody: (res),
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'reload page'
				}))
		} else {
			yield put({type:"ADD_REDUCER", data:response.data.region})
		}
		yield put(loaded())
	} catch (error) {
		if (error.message) {
			yield put(loaded())
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: (error.name),
					msgConfirmBody: (error.message),
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'try again'
				}))
		} else {
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: 'Failed load Reagions',
					msgConfirmBody: 'connetion Error',
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'try again'
				}
			))
			yield put(loaded())
		}
	}
}
function* watchAddRegion() {
	yield takeEvery(actionTypes.ADD_REGION_SAGA, addRegion)
}
function* deleteRegion(action) {
	try {
		yield put(loading())
		console.log("dele")
		const response = yield API.get(`https://antiksef.online/resturant_staging/bos_service/public/api/v1/regions/delete/${action.id.id}`)
		yield put({type:"DELETE_REDUCER", id:action.id.id})
		if (!response.data.isSuccessful) {
			let res = ""
			if (!response.data.message) { res = "error" } else {
				Object.values(response.data.message).map((value, index) => {
					res = `${res + index} : ${value}`
				})
			}
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: ("error"),
					msgConfirmBody: (res),
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'reload page'
				}))
		}
		yield put(loaded())
	} catch (error) {
		console.error(error)
		if (error.message) {
			yield put(loaded())
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: (error.name),
					msgConfirmBody: (error.message),
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'try again'
				}))
		} else {
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: 'Failed load Reagions',
					msgConfirmBody: 'connetion Error',
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'try again'
				}
			))
			yield put(loaded())
		}
	}
}

function* watchDeleteRegion() {
	yield takeEvery(actionTypes.DELETE_REGION_SAGA, deleteRegion)
}
function* updateRegion(action) {
	try {
		yield put(loading())
		const response = yield API.post(`${actionTypes.RESTAURANT_URL}/api/v1/regions/create`, JSON.stringify(action.region), {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		yield put({type:"UPDATE_REDUCER", data:response.data.region})
		if (!response.isSuccessful) {
			let res = ""
			if (!response.data.message) { res = "error" } else {
				Object.values(response.data.message).map((value, index) => {
					res = `${res + index} : ${value}`
				})
			}
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: ("error"),
					msgConfirmBody: (res),
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'reload page'
				}))
		} else {
	yield put({type:"UPDATE_REDUCER", data:response.data.region})
		}
		yield put(loaded())
	} catch (error) {
		if (error.message) {
			yield put(loaded())
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: (error.name),
					msgConfirmBody: (error.message),
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'try again'
				}))
		} else {
			yield put(showConfirmationMsg(
				{
					showConfirmMsg: "true",
					msgConfirmTitle: 'Failed load Reagions',
					msgConfirmBody: 'connetion Error',
					colorConfirmBtn: 'danger',
					txtConfirmBtn: 'try again'
				}
			))
			yield put(loaded())
		}
	}
}

function* watchUpdateRegion() {
	yield takeEvery(actionTypes.UPDATE_REGION_SAGA, updateRegion)
}

function* LanguagesSaga() {
	yield all([
		fork(watchGetLanguages),
		fork(watchGetLanguage),
		fork(watchUpdateLanguage),
		fork(watchDeleteLanguage),
		fork(watchAddLanguage),
		fork(watchGetCategory),
		fork(watchAddCategory),
		fork(watchGetCategoriesData),
		fork(watchDeleteCategory),
		fork(watchUpdateCategory),
		fork(watchGetProjectRegion),
		fork(watchAddRegion),
		fork(watchDeleteRegion),
		fork(watchUpdateRegion),
		fork(watchGetNextProjectRegion),
		fork(watchGetStatues),
		fork(watchGetUsers),
		fork(watchGetRoles),
		fork(watchGetTasks),
		fork(CategorySaga),
		fork(TranslationsSaga),
		fork(watchgetMsgs),
		fork(watchgetMsgsPage),
		fork(watchsend)
	])
}

export default LanguagesSaga
