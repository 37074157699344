import * as actionTypes from '../constants/translation'
import i18n from '../../components/global/helpers/i18n'


export const getLanguagesSaga = () => {
	// alert ('action')
	return {
		type: actionTypes.GET_Languages_SAGA
	}
}
export const getLanguagesReducer = (payload) => {
	return {
		type: actionTypes.GET_Languages_REDUCER,
		payload
	}
}
export const getLanguageSaga = () => {
	// alert ('action')
	return {
		type: actionTypes.GET_Language_SAGA
	}
}
export const getLanguageReducer = (payload) => {
	return {
		type: actionTypes.GET_Language_REDUCER,
		payload
	}
}
export const addLanguagesSaga = (Languages) => ({
	type: actionTypes.ADD_Languages_SAGA,
	payload: Languages
})

export const addLanguagesReducer = (Languages) => ({
	type: actionTypes.ADD_Languages_REDUCER,
	payload: Languages
})
export const deleteLanguagesSaga = (id) => ({
	type: actionTypes.DELETE_Languages_SAGA,
	id
})

export const deleteLanguagesReducer = (id) => ({
	type: actionTypes.DELETE_Languages_REDUCER,
	id
})
export const updateLanguagesSaga = (Languages) => ({
	type: actionTypes.UPDATE_Languages_SAGA,
	payload: Languages
})

export const updateLanguagesReducer = (Languages) => ({
	type: actionTypes.UPDATE_Languages_REDUCER,
	payload: Languages
})

// export const waiting = () => {
// =======
// import { DONE, LOADING ,SHOW_CONFIRM_MSG ,HIDE_CONFIRM_MSG } from "./constants"

export const waiting = () => {
// >>>>>>> 9661ed62a051a4470bf85514e868e2e8c3991fca
	return {
		type: actionTypes.LOADING
	}
}

// <<<<<<< HEAD
// export const stopWaiting = () => {
// =======
export const stopWaiting = () => {
// >>>>>>> 9661ed62a051a4470bf85514e868e2e8c3991fca
	return {
		type: actionTypes.DONE
	}
}

// <<<<<<< HEAD
export const loaded = () => {

	return {

		type: actionTypes.DONE

	}

}
export const loading = () => {

	return {

		type: actionTypes.LOADING

	}

}
// export const showConfirmationMsg = (confirmInfoPayload) => {
// =======
export const showConfirmationMsg = (confirmInfoPayload)  => {
// >>>>>>> 9661ed62a051a4470bf85514e868e2e8c3991fca
	return {
		type: actionTypes.SHOW_CONFIRM_MSG,
		payload: confirmInfoPayload
	}
}

// <<<<<<< HEAD
export const hideConfrirnMsg = () => {
	return {
		type: actionTypes.HIDE_CONFIRM_MSG
	}
}


// ==========================================================================
//                                LANGUAGES
// ==========================================================================


const getLanguages = () => ({
	type: actionTypes.LANGUAGES.GET_ALL_LANGUAGES
})

const getLanguagesSuccess = (payload) => ({
	type: actionTypes.LANGUAGES.GET_ALL_LANGUAGES_SUCCESS,
	payload
})

const getLanguagesFailed = (payload) => ({
	type: actionTypes.LANGUAGES.GET_ALL_LANGUAGES_FAILD,
	payload
})

const changeLanguage = ({ language_code }) => {
	i18n.changeLanguage(language_code)
	return {
		type: actionTypes.LANGUAGES.CHANGE_LANGUAGE,
		language_code
	}
}

// ==========================================================================
//                                REGIONS
// ==========================================================================


const getRegions = () => ({
	type: actionTypes.REGIONS.GET_ALL_REGIONS
})

const getRegionsSuccess = (payload) => ({
	type: actionTypes.REGIONS.GET_ALL_REGIONS_SUCCESS,
	payload
})

const getRegionsFailed = (payload) => ({
	type: actionTypes.REGIONS.GET_ALL_REGIONS_FAILD,
	payload
})


// const changeLanguage = ({ id }) => ({
//     type: LANGUAGES.CHANGE_LANGUAGE,
//     id
// })

export const actions = {
	languages: {
		getLanguages,
		getLanguagesSuccess,
		getLanguagesFailed,
		changeLanguage
	},
	regions: {
		getRegions,
		getRegionsSuccess,
		getRegionsFailed
	}
// =======
// export const hideConfrirnMsg = () =>{
//     return {
//         type: HIDE_CONFIRM_MSG
//     }
// >>>>>>> 9661ed62a051a4470bf85514e868e2e8c3991fca
}
