export const GET_Languages_SAGA = "GET_Languages_SAGA"

export const GET_Languages_REDUCER = "GET_Languages_REDUCER"

export const GET_Language_SAGA = "GET_Language_SAGA"

export const GET_Language_REDUCER = "GET_Language_REDUCER"

export const ADD_Languages_SAGA = "ADD_Languages_SAGA"

export const ADD_Languages_REDUCER = "ADD_Languages_REDUCER"

export const DELETE_Languages_SAGA = "DELETE_Languages_SAGA"

export const DELETE_Languages_REDUCER = "DELETE_Languages_REDUCER"

export const UPDATE_Languages_SAGA = "UPDATE_Languages_SAGA"
export const UPDATE_REGION_URL = 'regions/update'
export const UPDATE_Languages_REDUCER = "UPDATE_Languages_REDUCER"
export const LANGUAGES_URL = "api/v1/languages/index"
export const ADD_LANGUAGES_URL = "api/v1/languages/create"
export const UPDATE_LANGUAGES_URL = "api/v1/languages/update"
export const DELETE_LANGUAGES_URL = "api/v1/languages/destroy"
export const SHOW_LANG_URL = "api/v1/languages/show"
export const BACKEND_URL = `${process.env.REACT_APP_CATEGORIES_CONTENT_BACKEND_URL}/`
export const RESTAURANT_URL = `https://antiksef.online/resturant_staging/bos_service/public`
export const REGIONS_URL = '/api/v1/regions/index'
export const BACKEND_FIles_URL = `${process.env.REACT_APP_CATEGORIES_CONTENT_BACKEND_URL}/`
export const LOADING = "LOADING"
export const DONE = "DONE"
export const SHOW_CONFIRM_MSG = "SHOW_CONFIRM_MSG"
export const HIDE_CONFIRM_MSG = "HIDE_CONFIRM_MSG"


export const LANGUAGES = {
    GET_ALL_LANGUAGES: 'GET_ALL_LANGUAGES',
    GET_ALL_LANGUAGES_SUCCESS: 'GET_ALL_LANGUAGES_SUCCESS',
    GET_ALL_LANGUAGES_FAILD: 'GET_ALL_LANGUAGES_FAILD',
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE_'
}

export const REGIONS = {
    GET_ALL_REGIONS: 'GET_ALL_REGIONS',
    GET_ALL_REGIONS_SUCCESS: 'GET_ALL_REGIONS_SUCCESS',
    GET_ALL_REGIONS_FAILD: 'GET_ALL_REGIONS_FAILD'
}

export const generalsEndpoints = {
    translation_languages: {
        all: 'api/v1/languages/index'

    },
    regions: {
        all: 'regions/index'
    }
}
export const ADD_CATEGORY_SAGA = 'ADD_CATEGORY_SAGA'
export const ADD_CATEGORY_REDUCER = 'ADD_CATEGORY_REDUCER'
export const ADD_DIGITAL_CATEGORY_SAGA = 'ADD_DIGITAL_CATEGORY_SAGA'
export const ADD_DIGITAL_CATEGORY_REDUCER = 'ADD_DIGITAL_CATEGORY_REDUCER'

export const GET_CATEGORIES_SAGA = "GET_CATEGORIES_SAGA"
export const GET_CATEGORIES_REDUCER = "GET_CATEGORIES_REDUCER"
export const REGIONS_Type_URL = '/api/v1/region_types/index'
export const GET_CATEGORIES_DATA_SAGA = "GET_CATEGORIES_DATA_SAGA"
export const GET_CATEGORIES_DATA_REDUCER = "GET_CATEGORIES_DATA_REDUCER"

export const DELETE_CATEGORY_SAGA = "DELETE_CATEGORY_SAGA"
export const DELETE_CATEGORY_REDUCER = "DELETE_CATEGORY_REDUCER"
export const ApiKey = {
    TinyMce: 'cye4h869moamnk1aoob17kwubyzctljbjgl4swyopmv93bbz',
    GoogleMab: 'AIzaSyAhFvZNO8wcUEzlg5o0W8-bwTv0eJ6cd-I'
}
export const UPDATE_CATEGORY_SAGA = "UPDATE_CATEGORY_SAGA"
export const UPDATE_CATEGORY_REDUCER = "UPDATE_CATEGORY_REDUCER"
export const GET_CATEGORY_URL = 'api/v1/categories/index'
export const DELETE_CATEGORY_URL = 'api/v1/categories/delete'
export const UPDATE_CATEGORY_URL = 'api/v1/categories/update'
export const ADD_CATEGORY_URL = 'api/v1/categories/add'

export const GET_LANGUAGES_URL = 'api/v1/languages/index'
export const GET_PAGES_URL = 'pages'
export const GET_REGIONS_URL = 'regions/index'
export const GET_PROJECT_REGION_SAGA = "GET_PROJECT_REGION_SAGA"

export const GET_NEXT_PROJECT_REGION_SAGA = "GET_NEXT_PROJECT_REGION_SAGA"

export const GET_PROJECT_REGION_REDUCER = "GET_PROJECT_REGION_REDUCER"

export const ADD_REGION_SAGA = "ADD_REGION_SAGA"

export const ADD_REGION_REDUCER = "ADD_REGION_REDUCER"

export const DELETE_REGION_SAGA = "DELETE_REGION_SAGA"

export const DELETE_REGION_REDUCER = "DELETE_REGION_REDUCER"

export const UPDATE_REGION_SAGA = "UPDATE_REGION_SAGA"

export const UPDATE_REGION_REDUCER = "UPDATE_REGION_REDUCER"
export const GET_REG = "GET_REG"
export const GET_RT = "GET_RT"
export const GET_RT_RED = "GET_RT_RED"
export const GET_RTID = "GET_RTID"
export const UPD_DBREG = "UPD_DBREG"
export const ADD_OFF_REG = "ADD_OFF_REG"