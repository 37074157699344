import { key } from './key'

export const ar = {
    translation: {
        
        [key.add_service]: 'أضافة خدمة',
        [key.details]: 'التفاصيل',
        [key.gallery]: 'الصور',
        [key.work_order]: 'قائمة العمل',
        [key.work_orders]: ' قائمة أوامر العمل في الخدمة',
        [key.work_orders_template]: 'قائمة أوامر العمل في الخدمة',

        [key.task]: 'المهام',
        [key.checklist]: 'خطوات التنفيذ',
        [key.content]: 'المحتوى',
        [key.questions]: 'الاسئلة',
        [key.regions]: 'المناطق',
        [key.worker]: ' الموظفين',


        // Normal operations
        [key.add]: 'اضافة',
        [key.add_service]: 'اضافة خدمة',
        [key.add_workorder]: ' اضافة قائمة عمل',
        [key.add_workorder_template]: 'اضافة أمر عمل للخدمة',
        [key.add_task]: 'اضافة مهمة',
        [key.add_checklist]: 'اضافة خطوة',

        [key.edit]: 'تعديل',
        [key.delete]: 'حذف',


        [key.save]: 'حفظ',
        [key.close]: 'اغلاق'
    }

}