import React from "react"
import { Circle, User, Calendar } from "react-feather"

export const Navigation = [
  {
    id: "userModule",
    title: "dashboard.user.nav.userManagement",
    icon: <User color={'#fff'} size={20} />,
    children: [
      {
        id: 'userList',
        title: 'dashboard.user.nav.userList',
        icon: <Circle size={12} />,
        navLink: '/user/list',
        action: 'call',
        resource: 'USR_VIEW_ROLE_LIST'
      },
      {
        id: 'userRole',
        title: 'dashboard.user.nav.userRole',
        icon: <Circle size={12} />,
        navLink: '/user/role/list',
        action: 'call',
        resource: 'USR_VIEW_ROLE_LIST'
      },
      {
        id: 'countries',
        title: 'dashboard.user.nav.countries',
        icon: <Circle size={12} />,
        navLink: '/countries/list',
        action: 'call',
        resource: 'NoPermissionCode'
      },
      {
        id: 'KycSetting',
        title: 'dashboard.user.nav.KycSettings',
        icon: <Circle size={12} />,
        navLink: '/user/kyc/levels',
        action: 'call',
        resource: 'NoPermissionCode'
      },
      {
        id: 'user.calender.calender',
        title: 'dashboard.user.calender.calender',
        icon: <Calendar size={14} />,
        navLink: '/user/calender',
        action: 'call',
        resource: 'NoPermissionCode'
      },
      {
        id: 'securityConfig',
        title: 'dashboard.user.nav.securityConfig',
        icon: <Circle size={12} />,
        navLink: '/user/setting/securityConfig',
        action: 'call',
        resource: 'USR_VIEW_SECURITY_CONFIG'
      },
      {
        id: 'AutoLogin',
        title: 'dashboard.user.nav.AutoLogin',
        icon: <Circle size={12} />,
        navLink: '/user/setting/AutoLogin',
        action: 'call',
        resource: 'USR_VIEW_AUTOLOGIN_CONFIG'
      },
      {
        id: 'KycList',
        title: 'dashboard.user.nav.KycList',
        icon: <Circle size={12} />,
        navLink: '/user/kyc/list',
        action: 'call',
        resource: 'NoPermissionCode'
      },
      {
        id: 'AttributeList',
        title: 'dashboard.user.nav.AttributeList',
        icon: <Circle size={12} />,
        navLink: '/user/extra-fields/list',
        action: 'call',
        resource: 'NoPermissionCode'
      },
      {
        id: 'AttributeTypeList',
        title: 'dashboard.user.nav.AttributeTypeList',
        icon: <Circle size={12} />,
        navLink: '/user/extra-fields-types/list',
        action: 'call',
        resource: 'NoPermissionCode'
      }
      // {
      //   id: 'userPermissions',
      //   title: 'user.nav.userPermissions',
      //   icon: <Circle size={12} />,
      //   navLink: '/user/permissions',
      //   action: 'call',
      //   resource: 'NoPermissionCode'
      // },
      // {
      //   id: 'userSettings',
      //   title: 'user.nav.userSettings',
      //   icon: <Circle size={12} />,
      //   navLink: '/user/settings',
      //   action: 'call',
      //   resource: 'NoPermissionCode'
      // }
    ]
  }
]
