import * as actionTypes from '../constants/index'
import { ADD_CATEGORY_REDUCER, DELETE_CATEGORY_REDUCER, GET_CATEGORIES_DATA_REDUCER, GET_CATEGORIES_REDUCER } from "../constants/index"

// **  Initial State
const initialState = {
  loading: false,
  languages: [],
  lang: {},
  categoryId: 0,
  rdata: {},
  rtype: {},
  categories: [],
  categories_data: {
  },
  statues: [],
  users: [],
  roles: [],
  tasks: [],
  msgs: [],
  lan_code: null,
  lan_name: null,
  next: '',
  prev: ''
}

export const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ROLES_RED":
      {
        return {
          ...state,
          roles: action.payload
        }
      }
    case "lan_code":
      {
        return {
          ...state,
          lan_code: action.data
        }
      }
    case "lan_name":
      {
        return {
          ...state,
          lan_name: action.data
        }
      }
    case "languages_get":
      return ({
        ...state,
        languages: action.data
      })
    case "region_type_data" :
      {
        return  {
          ...state,
          rtype: action.data
        }
      }
    case actionTypes.GET_Languages_REDUCER:
      {
        return {
          ...state,
          languages: action.payload.languages
        }
      }
    case actionTypes.GET_Language_REDUCER:
      {
        return {
          ...state,
          lang: action.payload
        }
      }
    case actionTypes.GET_Languages_REDUCER:
      {
        return {
          ...state,
          languages: action.payload.languages
        }
      }
    case actionTypes.GET_Language_REDUCER:
      {
        return {
          ...state,
          lang: action.payload
        }
      }
    case GET_CATEGORIES_REDUCER: {
      return {
        ...state,
        categories: action.payload
      }
    }
    case ADD_CATEGORY_REDUCER: {
      return state
    }

    case GET_CATEGORIES_DATA_REDUCER: {
      return {
        ...state,
        categories_data: action.data
      }
    }
    case "STATUES_RED": {
      console.log(action.payload)
      return {
        ...state,
        statues: action.payload
      }
    }
    case "USER_RED": {
      return {
        ...state,
        users: action.payload
      }
    }
    case DELETE_CATEGORY_REDUCER: {
      return {
        ...state,
        categories: {
          ...state.categories,
          data: state.categories.data
        }
      }
    }
    case actionTypes.GET_PROJECT_REGION_REDUCER:
      {
        // alert ('reducer');
        return {
          ...state,
          rdata: action.payload.rdata,
          rtype: action.payload.rtype,
          languages: action.payload.languages

        }
      }
    case "TASKS_RED": {
      return {
        ...state,
        tasks: action.payload
      }
    }
    case "MSGS_RED": {
      return {
        ...state,
        msgs: action.payload,
        next: action.next,
        prev: action.prev,
        loading: false
      }
    }
    case "MSGS_RED_PAGE": {
      return {
        ...state,
        msgs: action.payload,
        next: action.next,
        prev: action.prev,
        loading: false
      }
    }
    case "LOADING_MSGS": {
      return {
        ...state,
        loading: true
      }
    }
    case "MSGS_RED_PAGE": {
      return {
        ...state,
        msgs: action.payload,
        next: action.next,
        prev: action.prev,
        loading: false
      }
    }
    case "LOADING_MSGS": {
      return {
        ...state,
        loading: true
      }
    }
    case "DELETE_REDUCER": {
      return {
        ...state,
        rdata: state.rdata.filter((region) => region.id !== action.id)
      }
    }
    case "UPDATE_REDUCER": {
      const a = []
      state.rdata.map((r) => {
        if (r.id === action.data.id) a.push(action.data)
        else a.push(r)
      })
      return {
        ...state,
        rdata: a
      }
    }
    case "ADD_REDUCER": {
      return {
        ...state,
        rdata: [...state.rdata, action.data]
      }
    }
    case actionTypes.ADD_REGION_REDUCER: {
      return state
    }
    default:
      return state
  }
}