export const GET_Languages_SAGA = "GET_Languages_SAGA"

export const GET_Languages_REDUCER = "GET_Languages_REDUCER"

export const GET_Language_SAGA = "GET_Language_SAGA"

export const GET_Language_REDUCER = "GET_Language_REDUCER"

export const ADD_Languages_SAGA = "ADD_Languages_SAGA"

export const ADD_Languages_REDUCER = "ADD_Languages_REDUCER"

export const DELETE_Languages_SAGA = "DELETE_Languages_SAGA"

export const DELETE_Languages_REDUCER = "DELETE_Languages_REDUCER"

export const UPDATE_Languages_SAGA = "UPDATE_Languages_SAGA"

export const UPDATE_Languages_REDUCER = "UPDATE_Languages_REDUCER"
export const  LANGUAGES_URL = "languages/index"
export const  ADD_LANGUAGES_URL = "languages/create"
export const  UPDATE_LANGUAGES_URL = "languages/update"
export const  DELETE_LANGUAGES_URL = "languages/destroy"
export const  SHOW_LANG_URL = "languages/show"
export const BACKEND_URL = "https://ramaaz.dev/market-back-end2/public/"

export const BACKEND_FIles_URL = "https://ramaaz.dev/market-back-end2/public/"
export const LOADING = "LOADING"
export const DONE = "DONE"
export const SHOW_CONFIRM_MSG = "SHOW_CONFIRM_MSG"
export const HIDE_CONFIRM_MSG = "HIDE_CONFIRM_MSG"


export const LANGUAGES = {
	GET_ALL_LANGUAGES: 'GET_ALL_LANGUAGES',
	GET_ALL_LANGUAGES_SUCCESS: 'GET_ALL_LANGUAGES_SUCCESS',
	GET_ALL_LANGUAGES_FAILD: 'GET_ALL_LANGUAGES_FAILD',
	CHANGE_LANGUAGE: 'CHANGE_LANGUAGE_'
}

export const REGIONS = {
	GET_ALL_REGIONS: 'GET_ALL_REGIONS',
	GET_ALL_REGIONS_SUCCESS: 'GET_ALL_REGIONS_SUCCESS',
	GET_ALL_REGIONS_FAILD: 'GET_ALL_REGIONS_FAILD'
}

export const generalsEndpoints = {
	translation_languages: {
		all: 'languages/index'

	},
	regions: {
		all: 'regions/index'
	}
}
