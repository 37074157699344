import { lazy } from "react"

export const Routes = [
    {
        path: "/settings/category-list",
        component: lazy(() => import("../views/categories")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/settings/regions-list",
        component: lazy(() => import("../views/regions")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/settings/translation-list",
        component: lazy(() => import("../views/translation-datatable")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/settings/regions",
        component: lazy(() => import("../views/regions")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/settings/blocking_countries",
        component: lazy(() => import("../views/security-settings/blockingCountry")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    }, {
        path: "/settings/blockingIps",
        component: lazy(() => import("../views/security-settings/BlockingIPsList")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    }, {
        path: "/settings/blockingSubnets",
        component: lazy(() => import("../views/security-settings/blockingSubnet")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    }, {
        path: "/settings/currency",
        component: lazy(() => import("../views/currency/currency_list")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    }, {
        path: "/settings/mobileBugs",
        component: lazy(() => import("../views/mobileBugs/index")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    }
]

