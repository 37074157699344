const PluggedInModules = {}
// dont add any module above service_shipping-employee temporary
// dont add any module above service_shipping-employee temporary
// dont add any module above service_shipping-employee temporary
// dont add any module above service_shipping-employee temporary
// dont add any module above service_shipping-employee temporary
// dont add any module above service_shipping-employee temporary
// dont add any module above service_shipping-employee temporary
// dont add any module above service_shipping-employee temporary
// dont add any module above service_shipping-employee temporary
// dont add any module above service_shipping-employee temporary
// dont add any module above service_shipping-employee temporary
try {
	PluggedInModules['service_shipping-employee'] = { path: require('@devModules/service-shipping-employee'), enabled: true } // account Module ()
} catch (e) {
	console.error('no service-shipping Module', e)
}
try {
	PluggedInModules['user'] = { path: require('@devModules/user'), enabled: true } // special user Module ()
} catch (e) {
	console.error('no Ewallet User Module', e)
}
try {
	PluggedInModules['service_shipping'] = { path: require('@devModules/service-shipping'), enabled: true } // account Module ()
} catch (e) {
	console.error('no service-shipping Module', e)
}
try {
	PluggedInModules['settings'] = { path: require('@devModules/settings'), enabled: true } // settings Module ()
} catch (e) {
	console.error('no settings Module', e)
}
// try {
// 	PluggedInModules['stock'] = { path: require('@devModules/stock'), enabled: true } // settings Module ()
// } catch (e) {
// 	console.error('no stock Module', e)
// }
// try {
// 	PluggedInModules['service_request'] = { path: require('@devModules/service-request'), enabled: true } // questions Module ()
// } catch (e) {
// 	console.error('no service-request Module', e)
// }
// try {
// 	PluggedInModules['product_service'] = { path: require('@devModules/product_service'), enabled: true } // product_service Module ()
// } catch (e) {
// 	console.error('no product_service Module', e)
// }
//  try {
//  	PluggedInModules['companies'] = { path: require('@devModules/companies'), enabled: true } // companies Module ()
//  } catch (e) {
//  	console.error('no companies Module', e)
//  }
//
// try {
// 	PluggedInModules['helpdesk'] = { path: require('@devModules/helpdesk'), enabled: true } // helpdesk Module ()
// } catch (e) {
// 	console.error('no helpdesk Module', e)
// }

// try {
// 	PluggedInModules['service'] = { path: require('@devModules/service'), enabled: true } // account Module ()
// } catch (e) {
// 	console.error('no service Module', e)
// }
// try {
// 	PluggedInModules['chat'] = { path: require('@devModules/chat'), enabled: true } // Chatting Module (RTC | VOE | CS)
// } catch (e) {
// 	console.error('no chat Module', e)
// }
export default PluggedInModules
