import React from "react"
import { Globe, Settings, Circle, User } from "react-feather"

export const Navigation = [
    {
        id: "translationModule",
        title: "dashboard.setting.nav.setting",
        icon: <Settings color={'#fff'} size={20} />,
        children: [
            {
                id: 'translationList',
                title: 'dashboard.setting.nav.translationList',
                icon: <Globe size={12} />,
                navLink: '/settings/translation-list',
                action: 'call',
                resource: 'NoPermissionCode'
            },
            {
                id: 'regionsList',
                title: 'dashboard.setting.nav.regionsList',
                icon: <Globe size={12} />,
                navLink: '/settings/regions-list',
                action: 'call',
                resource: 'NoPermissionCode'
            },
            {
                id: 'currencyList',
                title: 'dashboard.setting.nav.currencyList',
                icon: <Globe size={12} />,
                navLink: '/settings/currency',
                action: 'call',
                resource: 'NoPermissionCode'
            },
            {
                id: 'categoryList',
                title: 'dashboard.setting.nav.categoryList',
                icon: <Circle size={12} />,
                navLink: '/settings/category-list',
                action: 'call',
                resource: 'NoPermissionCode'
            },
            // {
            //     id: 'regions.regions',
            //     title: 'Regions',
            //     icon: <Globe size={12} />,
            //     navLink: '/settings/regions',
            //     action: 'call',
            //     resource: 'NoPermissionCode'
            // },
            {
                id: 'Blocking Countries',
                title: 'dashboard.setting.nav.blocking_countries',
                icon: <Circle size={12} />,
                navLink: '/settings/blocking_countries',
                action: 'call',
                resource: 'NoPermissionCode'
            }, {
                id: 'Blocking Ips',
                title: 'dashboard.setting.nav.blocking_ips',
                icon: <Circle size={12} />,
                navLink: '/settings/blockingIps',
                action: 'call',
                resource: 'NoPermissionCode'
            }, {
                id: 'Blocking Subnets',
                title: 'dashboard.setting.nav.blocking_subnets',
                icon: <Circle size={12} />,
                navLink: '/settings/blockingSubnets',
                action: 'call',
                resource: 'NoPermissionCode'
            }, {
                id: 'Mobile Bugs',
                title: 'dashboard.setting.nav.mobile_bugs',
                icon: <Circle size={12} />,
                navLink: '/settings/mobileBugs',
                action: 'call',
                resource: 'NoPermissionCode'
            }
        ]


    }
]
