export const key = {
    add_service: 'add_service',
    details: 'details',
    gallery: 'gallery',
    work_order: 'work_order',
    work_orders: 'work_orders',
    work_orders_template: 'work_orders_template',
    task: 'task',
    checklist: 'checklist',
    content: 'content',
    questions: 'questions',
    regions: 'regions',
    worker: 'worker',
    save: 'save',
    close: 'close',


    // Normal operations
    add: 'add',
    add_workorder: 'add_workorder',
    add_workorder_template: 'add_workorder_template',
    add_task: 'add_task',
    add_checklist: 'add_checklist',

    edit: 'edit',
    delete: 'delete'

}