import i18n from 'i18next'
import { ar } from './locales/ar'
import { en } from './locales/en'
import { useTranslation, initReactI18next } from "react-i18next"


const resources = {
    EN: en,
    AR: ar

}
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "AR",
        // fallbackLng: "ar",

        interpolation: {
            escapeValue: false
        }
    })

export default i18n