
const INIT_STATE = {
    vendors: [],
    idSystemCompany: null,
    lang: [],
    users: [],
    customersForOrdersList: [],
    regionsForServices: [],
    stocksForServices: [],
    companies: [],
    companyAdmins: [],
    orderTypes: [],
    orderTypesOptions: [],
    serviceLevels: [],
    tasksStatuses: [],
    regions: [],
    regionsCord: [],
    systemCompanies: [],
    userStatuses: [],
    materials: [],
    measureUnits: [],
    measureUnitsDefault: [],
    foodMealsServices: [],
    languages: [],
    serviceId: null,
    workOrderId: null,
    workOrderTaskId: null,
    categories: [],
    roles: [],
    bo_statuses_options: [],
    wo_statuses_options: [],
    task_statuses_options: [],
    employeesInOnePage: [],
    employees: [],
    employeesForServiceLevel: [],
    rolesForServiceLevel: [],
    bo_id: null,
    boLists: [],
    currentItem: {},
    loading: false,
    configs: null,
    services: [],
    boStatuses: [],
    woRequestStatuses: [],
    woRequestTaskStatuses: [],
    orderStatuses: [],
    DatatableCustomers: [],
    categoriesSelect: [],
    categoriesForEditService: [],
    categories_data: {
    },
    categories_for_cashier: [],
    stocksForOrder: [],
    systemCompanyBaseUrl: '',
    materials_for_cashier: [],
    types_services_for_cashier: [],
    cities: [],
    countries: [],
    starting_centers: [],
    user_employees: [],
    role_employees: [],
    week_days: [],
    orderId: null,
    serviceLevelId: null,
    journeyId: null,
    serviceLevelIdForAssign: null,
    journey_status:[]
}

const Reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'JOURNEY_ID': {
            return { ...state, journeyId: action.payload }
        }
        case 'JOURNEY_STATUS': {
            return { ...state, journey_status: action.payload }
        }
        case 'ROLE_EMPLOYEES': {
            return { ...state, role_employees: action.payload }
        }
        case 'USER_EMPLOYEES': {
            return { ...state, user_employees: action.payload }
        }
        case 'STARTING_CENTER_ALL': {
            return { ...state, starting_centers: action.payload }
        }
        case 'CITIES': {
            return { ...state, cities: action.payload }
        }
        case 'COUNTRIES': {
            return { ...state, countries: action.payload }
        }
        case 'COMPANYKEY': {
            return { ...state, companyKey: action.payload }
        }
        case 'WEEK_DAYS': {
            return { ...state, week_days: action.payload }
        }
        case 'SERVICES': {
            return { ...state, services: action.payload }
        }
        case 'ID_SYSTEM_COMPANY': {
            return { ...state, idSystemCompany: action.payload }
        }
        case 'SET_MATERIALS': {
            return { ...state, materials: action.data }
        }
        case 'SET_MEASURE_UNITS': {
            return { ...state, measureUnits: action.data }
        }
        case 'SET_MEASURE_UNITS_DEFAULTS': {
            return { ...state, measureUnitsDefault: action.data }
        }
        case 'SET_FOOD_MEALS_SERVICES': {
            return { ...state, foodMealsServices: action.data }
        }
        case 'change_current_service': {
            return { ...state, serviceId: action.payload }
        }
        case 'change_current_work_order': {
            return { ...state, workOrderId: action.payload }
        }
        case 'change_current_work_order_task': {
            return { ...state, workOrderTaskId: action.payload }
        }
        case 'store_bos_statuses': {
            return { ...state, bo_statuses_options: action.payload }
        }
        case 'store_task_statuses': {
            return { ...state, task_statuses_options: action.payload }
        }
        case 'store_wos_statuses': {
            return { ...state, wo_statuses_options: action.payload }
        }
        case "languages_get":
            return ({
                ...state,
                languages: action.data
            })
        case "currentItem_set":
            return ({
                ...state,
                currentItem: action.data
            })
        case "categories_get":
            return ({
                ...state,
                categories: action.data
            })
        case "STORE_REGIONS_FOR_SERVICES":
            return ({
                ...state,
                regionsForServices: action.payload
            })
        case "autoAssignRoles":
            return ({
                ...state,
                roles: action.payload
            })
        case "STORE_EMPLOYEES":
            return ({
                ...state,
                employees: action.payload
            })
        case "STORE_EMPLOYEES_IN_ONE_PAGE":
            return ({
                ...state,
                employeesInOnePage: action.payload
            })
        case "STORE_EMPLOYEES_FOR_SERVICE_LEVEL":
            return ({
                ...state,
                employeesForServiceLevel: action.payload
            })
        case "SERVICE_LEVEL_ID_FOR_ASSIGN":
            return ({
                ...state,
                serviceLevelIdForAssign: action.payload
            })
        case "STORE_ROLES_FOR_SERVICE_LEVEL":
            return ({
                ...state,
                rolesForServiceLevel: action.payload
            })
        case "BO_ID":
            return ({
                ...state,
                bo_id: action.payload
            })
        case "BOS_LISTS":
            return ({
                ...state,
                boLists: action.payload
            })
        case "SET_COMPANIES":
            return ({
                ...state,
                companies: action.payload
            })
        case "SET_ORDER_TYPES":
            return ({
                ...state,
                orderTypes: action.payload
            })
        case "SET_ORDER_TYPES_OPTIONS":
            return ({
                ...state,
                orderTypesOptions: action.payload
            })
        case "SET_SERVICES_LEVELS":
            return ({
                ...state,
                serviceLevels: action.payload
            })
        case "SET_TASKS_STATUSES":
            return ({
                ...state,
                tasksStatuses: action.payload
            })
        case "SET_REGIONS":
            return ({
                ...state,
                regions: action.payload
            })
        case "SET_REGIONS_CORD":
            return ({
                ...state,
                regionsCord: action.payload
            })
        case 'storeDatatableCustomers':
            return ({
                ...state,
                DatatableCustomers: action.payload
            })
        case "store_configs":
            return ({
                ...state,
                configs: action.payload
            })
        case "storeCategoriesForSelects":
            return ({
                ...state,
                categoriesSelect: action.payload
            })
        case "getCategoriesDataReducer": {
            return {
                ...state,
                categories_data: action.data
            }
        }
        case "getCategoriesReducer": {
            return {
                ...state,
                categories: action.payload
            }
        }
        case "cashier_need_food":
            return ({
                ...state,
                categories_for_cashier: action.data
            })
        case "cashier_need_materials":
            return ({
                ...state,
                materials_for_cashier: action.data
            })
        case "storeOrderTypesServices":
            return ({
                ...state,
                types_services_for_cashier: action.payload
            })
        case "BO_STATUSES_GET":
            return ({
                ...state,
                boStatuses: action.data
            })
        case "WO_REQUEST_STATUSES_GET":
            return ({
                ...state,
                woRequestStatuses: action.data
            })
        case "WO_REQUEST_TASK_STATUSES_GET":
            return ({
                ...state,
                woRequestTaskStatuses: action.data
            })
        case "ORDER_STATUSES_GET":
            return ({
                ...state,
                orderStatuses: action.data
            })
        case "ORDER_ID":
            return ({
                ...state,
                orderId: action.payload
            })
        case "SERVICE_LEVELS_GET":
            return ({
                ...state,
                serviceLevels: action.payload
            })
        case "SERVICE_LEVEL_ID":
            return ({
                ...state,
                serviceLevelId: action.payload
            })
        case "SET_SERVICES":
            return ({
                ...state,
                services: action.data
            })
        case "STOCKS_FOR_ORDER":
            return ({
                ...state,
                stocksForOrder: action.payload
            })
        case "SYSTEM_COMPANY_BASE_URL":
            return ({
                ...state,
                systemCompanyBaseUrl: action.payload
            })
        case "STORE_COMPANY_ADMINS":
            return ({
                ...state,
                companyAdmins: action.payload
            })
        case "SYSTEM_COMPANIES_GET":
            return ({
                ...state,
                systemCompanies: action.payload
            })
        case "STORE_CUSTOMERS_FOR_ORDERS_PAGE":
            return ({
                ...state,
                customersForOrdersList: action.payload
            })
        case "STORE_STOCKS_FOR_SERVICES":
            return ({
                ...state,
                stocksForServices: action.payload
            })
        default:
            return { ...state }
    }
}

export default Reducer
