import React from "react"
import {Circle, HelpCircle, User} from "react-feather"

export const Navigation = [
    {
        id: "serviceShippingModule",
        title: "dashboard.service-shipping.nav.serviceShippingManagement",
        icon: <User color={'#fff'} size={20}/>,
        children: [
            {
                id: "serviceShipping",
                title: "dashboard.service-shipping.nav.serviceShipping",
                icon: <User color={'#fff'} size={20}/>,
                children: [
                    {
                        id: 'shippingEmployee',
                        title: 'dashboard.service-shipping.nav.shippingEmployee',
                        icon: <Circle size={12}/>,
                        navLink: '/shippingEmployee/list',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'cities',
                        title: 'dashboard.service-shipping.nav.cities',
                        icon: <Circle size={12}/>,
                        navLink: '/cities/list',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'journey',
                        title: 'dashboard.service-shipping.nav.journey',
                        icon: <Circle size={12}/>,
                        navLink: '/journey/list',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'journeyStatus',
                        title: 'dashboard.service-shipping.nav.journeyStatus',
                        icon: <Circle size={12}/>,
                        navLink: '/journeyStatus/list',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'BOSShippingList',
                        title: 'dashboard.service-shipping.nav.BOSList',
                        icon: <Circle size={12}/>,
                        navLink: '/BOSShipping/list',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'serviceShippingList',
                        title: 'dashboard.service-shipping.nav.serviceList',
                        icon: <Circle size={12}/>,
                        navLink: '/ServiceShipping/list',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'workOrderTypesShippingList',
                        title: 'dashboard.service-shipping.nav.workOrderTypesList',
                        icon: <Circle size={12}/>,
                        navLink: '/Service/work-generatedOrdersList-types-Shipping-list',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'workOrderRequestStatusShippingList',
                        title: 'dashboard.service-shipping.nav.workOrderRequestStatusList',
                        icon: <Circle size={12}/>,
                        navLink: '/Service/work-generatedOrdersList-request-status-Shipping-list',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'workOrderRequestTaskStatusShippingList',
                        title: 'dashboard.service-shipping.nav.workOrderRequestTaskStatusList',
                        icon: <Circle size={12}/>,
                        navLink: '/Service/work-generatedOrdersList-request-task-status-Shipping-list',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'serviceRequestStatusShippingList',
                        title: 'dashboard.service-shipping.nav.serviceRequestStatusList',
                        icon: <Circle size={12}/>,
                        navLink: '/Service/service-request-status-Shipping-list',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'categoryToRoleShippingList',
                        title: 'dashboard.service-shipping.nav.categoryToRoleList',
                        icon: <Circle size={12}/>,
                        navLink: '/ServiceShipping/assign',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'serviceLevelsShipping',
                        title: 'dashboard.service-shipping.nav.serviceLevels',
                        icon: <Circle size={12}/>,
                        navLink: '/serviceLevelsShipping/list',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'tasksStatusesShipping',
                        title: 'dashboard.service-shipping.nav.tasksStatuses',
                        icon: <Circle size={12}/>,
                        navLink: '/tasksStatusesShipping/list',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    }
                ]
            },
            {
                id: "shippingOrders",
                title: "dashboard.service-shipping.nav.shippingOrders",
                icon: <HelpCircle color={'#fff'} size={20} />,
                children: [
                    // {
                    //     id: 'cashier_page_new_shipping',
                    //     title: 'dashboard.service-shipping.nav.cashier-page-new',
                    //     icon: <Circle size={12} />,
                    //     navLink: '/orders/cashier_page_new_shipping',
                    //     action: 'call',
                    //     resource: 'NoPermissionCode'
                    // },
                    {
                        id: 'orderDeliveryRequestShipping',
                        title: 'dashboard.service-shipping.nav.orderDeliveryRequests',
                        icon: <Circle size={12}/>,
                        navLink: '/order-delivery-request/list',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },  {
                        id: 'orderDeliveryRequestArchivedShipping',
                        title: 'dashboard.service-shipping.nav.orderDeliveryRequestsArchived',
                        icon: <Circle size={12}/>,
                        navLink: '/order-delivery-request-archived/list',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'shippingOrderList',
                        title: 'dashboard.service-shipping.nav.ordersList',
                        icon: <Circle size={12} />,
                        navLink: '/orders/shipping-list',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'shippingOrderArchivedList',
                        title: 'dashboard.service-shipping.nav.ordersArchivedList',
                        icon: <Circle size={12} />,
                        navLink: '/orders-archived/list',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'shippingOrderStatus',
                        title: 'dashboard.service-shipping.nav.orderStatusList',
                        icon: <Circle size={12} />,
                        navLink: '/orders/orderStatusShipping',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'shippingOrderTypes',
                        title: 'dashboard.service-shipping.nav.orderTypesList',
                        icon: <Circle size={12} />,
                        navLink: '/orders/orderTypesShipping',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'shippingOrderTypesServices',
                        title: 'dashboard.service-shipping.nav.orderTypesServicesList',
                        icon: <Circle size={12} />,
                        navLink: '/orders/orderTypesServicesShipping',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'boWoTaskStatusShipping',
                        title: 'dashboard.service-shipping.nav.bo-wo-task-status',
                        icon: <Circle size={12} />,
                        navLink: '/orders/boWoTaskStatusShipping',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'currentNextBoOrderStatusShipping',
                        title: 'dashboard.service-shipping.nav.current-next-bo-order-status',
                        icon: <Circle size={12} />,
                        navLink: '/orders/currentNextBoOrderStatusShipping',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'orderCategoryShippingList',
                        title: 'dashboard.service-shipping.nav.categoryList',
                        icon: <Circle size={12} />,
                        navLink: '/orders/category-shipping-list',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'shippingOrderConfigs',
                        title: 'dashboard.service-shipping.nav.order-configs',
                        icon: <Circle size={12} />,
                        navLink: '/orders/shippingOrderConfigs',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'tablesShipping',
                        title: 'dashboard.service-shipping.nav.tables',
                        icon: <Circle size={12} />,
                        navLink: '/orders/tablesShipping',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'systemCompanies',
                        title: 'dashboard.service-shipping.nav.system-companies',
                        icon: <Circle size={12} />,
                        navLink: '/orders/system-companies',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    }
                ]
            }
        ]
    }
]
