import axios from 'axios'
import { store } from "@fwsrc/redux/storeConfig/store"

export const getLanguagesFiles = async () => {
    await axios.get(`${process.env.REACT_APP_CATEGORIES_CONTENT_BACKEND_URL}/api/v1/languages/translation_files_content`)
        .then(function (res) {
            store.dispatch({
                type: "storeTranslations",
                data: res.data.data
            })
            localStorage.setItem('translations', JSON.stringify(res.data.data))
            return (res.data.data)
        }).catch((e) => {
        })
}