import {all, fork, takeEvery, call, put} from "redux-saga/effects"
import * as actionTypes from '../constants/translation'
import * as actions from "../actions/translation"
import {API} from "../../utility/API"

const getLanguages = () => {
	return API.get(actionTypes.LANGUAGES_URL)
}
const getLanguage = () => {
	return API.get(actionTypes.SHOW_LANG_URL)
}

export function* getLanguagesSaga(action) {
	try {
		const response = yield call(getLanguages)
		const payload = {languages:response.data}
		console.log('saga', response)
		yield put(actions.getLanguagesReducer(payload))
	} catch (error) {
		console.log(`Settings Module - sagas/translations - getLanguagesSaga :${ error}`)
	}
}
export function* watchGetLanguages() {
	yield takeEvery(actionTypes.GET_Languages_SAGA, getLanguagesSaga)
}
export function* getLanguageSaga(action) {
	try {
		const response = yield call(getLanguage)
		const payload = {lang:response.data.data}
		yield put(actions.getLanguageReducer(payload))
	} catch (error) {
		console.log(`Settings Module - sagas/translations - getLanguageSaga :${ error}`)
	}
}
export function* watchGetLanguage() {
	yield takeEvery(actionTypes.GET_Language_SAGA, getLanguageSaga)
}
function* addLanguage(action) {
	try {
		yield put(actions.loading())
		const response = yield API.post(actionTypes.ADD_LANGUAGES_URL, JSON.stringify(action.payload),  {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		if (!response.data.isSuccessful) {
			let res = ""
			if (!response.data.message) { res = "error" } else {
				Object.values(response.data.message).map((value, index) => {
					res = `${res + index  } : ${  value}`
				})
			}
			yield put(actions.showConfirmationMsg(
				{
					showConfirmMsg:"true",
					msgConfirmTitle:("error"),
					msgConfirmBody:(res),
					colorConfirmBtn:'danger',
					txtConfirmBtn:'reload page'
				}))
		} else {
			const response2 = yield call(getLanguages)
			const payload = {languages:response2.data.data}
			yield put(actions.getLanguagesReducer(payload))
		}
		yield put(actions.loaded())
	} catch (error) {
		if (error.message) {
			yield put(actions.loaded())
			yield put(actions.showConfirmationMsg(
				{
					showConfirmMsg:"true",
					msgConfirmTitle:(error.name),
					msgConfirmBody:(error.message),
					colorConfirmBtn:'danger',
					txtConfirmBtn:'try again'
				}))
		} else {
			yield put(actions.showConfirmationMsg(
				{
					showConfirmMsg:"true",
					msgConfirmTitle:'Failed load Translations',
					msgConfirmBody:'connetion Error',
					colorConfirmBtn:'danger',
					txtConfirmBtn:'try again'
				}
			))
			yield put(actions.loaded())
		}
	}
}
function* watchAddLanguage() {
	yield takeEvery(actionTypes.ADD_Languages_SAGA, addLanguage)
}
function* updateLanguage(action) {
	try {
		yield put(actions.loading())
		const response = yield API.post(actionTypes.UPDATE_LANGUAGES_URL, JSON.stringify(action.payload),  {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		if (!response.isSuccessful) {
			let res = ""
			if (!response.message) { res = "error" } else {
				Object.values(response.message).map((value, index) => {
					res = `${res + index  } : ${  value}`
				})
			}
			yield put(actions.showConfirmationMsg(
				{
					showConfirmMsg:"true",
					msgConfirmTitle:("error"),
					msgConfirmBody:(res),
					colorConfirmBtn:'danger',
					txtConfirmBtn:'reload page'
				}))
		} else {
			const response2 = yield call(getLanguages)
			const payload = {languages:response2.data.data}
			yield put(actions.getLanguagesReducer(payload))
		}
		yield put(actions.loaded())
	} catch (error) {
		if (error.message) {
			yield put(actions.loaded())
			yield put(actions.showConfirmationMsg(
				{
					showConfirmMsg:"true",
					msgConfirmTitle:(error.name),
					msgConfirmBody:(error.message),
					colorConfirmBtn:'danger',
					txtConfirmBtn:'try again'
				}))
		} else {
			yield put(actions.showConfirmationMsg(
				{
					showConfirmMsg:"true",
					msgConfirmTitle:'Failed load Translations',
					msgConfirmBody:'connetion Error',
					colorConfirmBtn:'danger',
					txtConfirmBtn:'try again'
				}
			))
			yield put(actions.loaded())
		}
	}
}
function* watchUpdateLanguage() {
	yield takeEvery(actionTypes.UPDATE_Languages_SAGA, updateLanguage)
}
function* deleteLanguage(action) {
	try {
		yield put(actions.loading())
		const response = yield API.post(actionTypes.DELETE_LANGUAGES_URL, JSON.stringify(action.id), {
			headers: {
				'Content-Type': 'application/json'
			}
		})
		if (!response.data.isSuccessful) {
			let res = ""
			if (!response.data.message) { res = "error" } else {
				Object.values(response.data.message).map((value, index) => {
					res = `${res + index  } : ${  value}`
				})
			}
			yield put(actions.showConfirmationMsg(
				{
					showConfirmMsg:"true",
					msgConfirmTitle:("error"),
					msgConfirmBody:(res),
					colorConfirmBtn:'danger',
					txtConfirmBtn:'reload page'
				}))
		} else {
			const response2 = yield call(getLanguages)
			const payload = {languages:response2.data.data}
			yield put(actions.getLanguagesReducer(payload))
		}
		yield put(actions.loaded())
	} catch (error) {
		if (error.message) {
			yield put(actions.loaded())
			yield put(actions.showConfirmationMsg(
				{
					showConfirmMsg:"true",
					msgConfirmTitle:(error.name),
					msgConfirmBody:(error.message),
					colorConfirmBtn:'danger',
					txtConfirmBtn:'try again'
				}))
		} else {
			yield put(actions.showConfirmationMsg(
				{
					showConfirmMsg:"true",
					msgConfirmTitle:'Failed load Translations',
					msgConfirmBody:'connetion Error',
					colorConfirmBtn:'danger',
					txtConfirmBtn:'try again'
				}
			))
			yield put(actions.loaded())
		}
	}
}

function* watchDeleteLanguage() {
	yield takeEvery(actionTypes.DELETE_Languages_SAGA, deleteLanguage)
}
export default function* TranslationsSaga() {
	yield all([
		fork(watchGetLanguages),
		fork(watchGetLanguage),
		fork(watchUpdateLanguage),
		fork(watchDeleteLanguage),
		fork(watchAddLanguage)
	])
}
