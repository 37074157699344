import { lazy } from "react"

export const Routes = [
    {
        path: "/BOSShipping/list",
        component: lazy(() => import("../views/my-bo/index")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/shippingEmployee/list",
        component: lazy(() => import("../views/shipping-employee/index")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/cities/list",
        component: lazy(() => import("../views/cities-list/index")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/journey/list",
        component: lazy(() => import("../views/journeys-list")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/journeyStatus/list",
        component: lazy(() => import("../views/journey-statuses")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/ServiceShipping/list",
        component: lazy(() => import("../views/service-list/index")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/Service/work-generatedOrdersList-types-Shipping-list",
        component: lazy(() => import("../views/work-order-types/index")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/Service/work-generatedOrdersList-request-status-Shipping-list",
        component: lazy(() => import("../views/work-order-request-status/index")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/Service/work-generatedOrdersList-request-task-status-Shipping-list",
        component: lazy(() => import("../views/wo-request-task-status/index")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/Service/service-request-status-Shipping-list",
        component: lazy(() => import("../views/service-request-status/index")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: '/ServiceShipping/assign',
        component: lazy(() => import("../views/auto-assign-config/index")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/serviceLevelsShipping/list",
        component: lazy(() => import("../views/service-levels/index")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/tasksStatusesShipping/list",
        component: lazy(() => import("../views/tasks-statuses-config/index")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: '/order-delivery-request/list',
        component: lazy(() => import("../views/order-delivery-request/index")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },    {
        path: '/order-delivery-request-archived/list',
        component: lazy(() => import("../views/order-delivery-request-archived/index")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/orders/cashier_page_new_shipping",
        component: lazy(() => import("../views/cashier-page-new")),
        meta: {
            action: 'call',
            resource: 'NoPermissionCode'
        }
    },
    {
        path: "/orders/shipping-list",
        component: lazy(() => import("../views/order")),
        meta: {
            action: 'call',
            resource: 'NoPermissionCode'
        }
    },
    {
        path: "/orders-Archived/list",
        component: lazy(() => import("../views/orders-archived/index")),
        meta: {
            action: 'call',
            resource: 'NoPermissionCode'
        }
    },
    {
        path: "/orders/orderTypesShipping",
        component: lazy(() => import("../views/order-type")),
        meta: {
            action: 'call',
            resource: 'NoPermissionCode'
        }
    },
    {
        path: "/orders/orderTypesServicesShipping",
        component: lazy(() => import("../views/order-type-to-service_link")),
        meta: {
            action: 'call',
            resource: 'NoPermissionCode'
        }
    },
    {
        path: "/orders/boWoTaskStatusShipping",
        component: lazy(() => import("../views/bo-wo-task-status")),
        meta: {
            action: 'call',
            resource: 'NoPermissionCode'
        }
    },
    {
        path: "/orders/currentNextBoOrderStatusShipping",
        component: lazy(() => import("../views/current-next-bo-order-status")),
        meta: {
            action: 'call',
            resource: 'NoPermissionCode'
        }
    },
    {
        path: "/orders/orderStatusShipping",
        component: lazy(() => import("../views/order-status")),
        meta: {
            action: 'call',
            resource: 'NoPermissionCode'
        }
    },
    {
        path: "/orders/shippingOrderConfigs",
        component: lazy(() => import("../views/order-configs")),
        meta: {
            action: 'call',
            resource: 'NoPermissionCode'
        }
    },
    {
        path: "/orders/category-shipping-list",
        component: lazy(() => import("../views/categories")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/orders/tablesShipping",
        component: lazy(() => import("../views/tables")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/orders/system-companies",
        component: lazy(() => import("../views/system-companies")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    }

]
