import { key } from './key'
export const en = {
    translation: {
        [key.add_service]: 'Add Service',
        [key.details]: 'Details',
        [key.gallery]: 'Gallery',
        [key.work_order]: 'Work order',
        [key.work_orders]: 'Work orders',
        [key.work_orders_template]: 'Work orders template',
        [key.task]: 'Task',
        [key.checklist]: 'Check List',
        [key.content]: 'Content',
        [key.questions]: 'Questions',
        [key.regions]: 'Regions',
        [key.worker]: 'Worker',

        // Normal operations
        [key.add]: 'Add',
        [key.add_service]: 'Add Service',
        [key.add_workorder]: 'Add Workorder',
        [key.add_workorder_template]: 'Add Workorder Template',
        [key.add_task]: 'Add Task',
        [key.add_checklist]: 'Add Checklist',

        [key.edit]: 'Edit',
        [key.delete]: 'Delete',


        [key.save]: 'save',
        [key.close]: 'close'
    }

}