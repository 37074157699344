// ** Logo
import themeConfig from '@configs/themeConfig'
import React from "react"

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner vw-100 vh-100 d-flex justify-content-center align-items-center'>
      <img  alt={"Logo"} width={"300px"} height={'200px'} src={themeConfig.app.appLogoImage} alt='logo' />
      <div className='loading'>
        <div className='effect-1 effects'/>
        <div className='effect-2 effects'/>
        <div className='effect-3 effects'/>
      </div>
    </div>
  )
}

export default SpinnerComponent
