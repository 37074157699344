import { lazy } from "react"

export const Routes = [
    {
        path: "/orders/my-orders",
        component: lazy(() => import("../views/my-orders")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    },
    {
        path: "/orders/my-orders-requests",
        component: lazy(() => import("../views/my-orders-requests")),
        meta: {
            action: "call",
            resource: "NoPermissionCode"
        }
    }
]
