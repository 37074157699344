import * as actionTypes from '../constants/index'
import { API } from "../../utility/API"
import { store } from "@fwsrc/redux/storeConfig/store"
import _ from "lodash"

export const _getCurrencyList = ({ limit, page, sort, filter }, callback) => {
    API.get('/api/v1/currencies', {
        params: {
            limit,
            page,
            sort,
            filter,
            datatables: 1
        }
    })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
        })
}
export const _getCurrencyType = async (q = '') => {
    const res = await API.get('/api/v1/currency_types')
    return _.map(res.data, (v, k) => {
        return { value: v.translation.currency_type_id, label: v.translation.name }
    })
}
export const _deleteCurrency = (id, callback) => {
    API.post(`/api/v1/currencies/destroy`, { id })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (data) {
        })
}
export const _addCurrency = (data, callback, callbackErr) => {
    API.post('/api/v1/currencies/store', data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}
export const _editCurrency = (data, callback, callbackErr) => {
    API.post(`/api/v1/currencies/update`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}
export const _getStatusList = ({ limit, page, sort, filter }, callback) => {
    API.get('/api/v1/task_statuses/dashboard', {
        params: {
            limit,
            page,
            sort,
            filter,
            datatables: 1
        }
    })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
        })
}
export const getMsg = (payload) => {
    return {
        type: "MSGS_SAGA",
        payload
    }
}
export const getMsgRed = (payload, next, prev) => {
    return {
        type: "MSGS_RED",
        next,
        prev,
        payload
    }
}
export const getPage = (payload, page) => {
    return {
        type: "MSGS_SAGA_PAGE",
        payload,
        page
    }
}
export const getPageRed = (payload, next, prev) => {
    return {
        type: "MSGS_RED_PAGE",
        next,
        prev,
        payload
    }
}
export const send = (payload) => {
    return {
        type: "MSGS_SAGA_SEND",
        payload
    }
}
export const loadeds = () => {
    return {
        type: 'LOAD_MSGS'
    }
}
export const loadings = () => {
    return {
        type: 'LOADING_MSGS'
    }
}

export const _getLanguageList = ({ limit, page, sort, filter }, callback) => {
    API.get(`${process.env.REACT_APP_CATEGORIES_CONTENT_BACKEND_URL}/api/v1/languages/all_languages`, {
        params: {
            limit,
            page,
            sort,
            filter,
            datatables: true,
            users_filter: []
        }
    })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) { })
}
export const _getSonLanguageList = ({ limit, page, sort, filter }, callback) => {
    const lan_code = store.getState().settings.lan_code
    API.get(`${process.env.REACT_APP_CATEGORIES_CONTENT_BACKEND_URL}/api/v1/languages/son_languages/${lan_code}`, {
        params: {
            limit,
            page,
            sort,
            filter,
            datatables: true,
            users_filter: []
        }
    })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) { })
}
//************************************//
export const _setLanguageFilePath = (data, code) => {
    API.post(`${process.env.REACT_APP_CATEGORIES_CONTENT_BACKEND_URL}/api/v1/languages/set_file_path/${code}`, {file_path: data})
    .then(function ({ data }) {
        // callback(data)
    })
    .catch(function (res) {
        // callbackErr(res)
    })
}
export const getLanguagesSaga = () => {
    // alert ('action')
    return {
        type: actionTypes.GET_Languages_SAGA
    }
}
export const getLanguagesReducer = (payload) => {
    return {
        type: actionTypes.GET_Languages_REDUCER,
        payload
    }
}
export const getLanguageSaga = () => {
    // alert ('action')
    return {
        type: actionTypes.GET_Language_SAGA
    }
}
export const getLanguageReducer = (payload) => {
    return {
        type: actionTypes.GET_Language_REDUCER,
        payload
    }
}
export const addLanguagesSaga = (Languages) => ({
    type: actionTypes.ADD_Languages_SAGA,
    payload: Languages
})

export const addLanguagesReducer = (Languages) => ({
    type: "ADD_Languages_REDUCER",
    payload: Languages
})
export const deleteLanguagesSaga = (id) => ({
    type: actionTypes.DELETE_Languages_SAGA,
    id
})

export const deleteLanguagesReducer = (id) => ({
    type: actionTypes.DELETE_Languages_REDUCER,
    id
})
export const updateLanguagesSaga = (Languages) => ({
    type: actionTypes.UPDATE_Languages_SAGA,
    payload: Languages
})

export const updateLanguagesReducer = (Languages) => ({
    type: actionTypes.UPDATE_Languages_REDUCER,
    payload: Languages
})
export const _addLanguage = (data, callback, callbackErr) => {
    API.post(`${process.env.REACT_APP_CATEGORIES_CONTENT_BACKEND_URL}/api/v1/languages/create`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}
//************************************//
export const _deleteLang = (data, callback) => {
    API.post(`${process.env.REACT_APP_CATEGORIES_CONTENT_BACKEND_URL}/api/v1/languages/destroy`, { language_code: data })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
        })
}
//************************************//
export const _editUserLang = (data, callback, callbackErr) => {
    API.post(`${process.env.REACT_APP_CATEGORIES_CONTENT_BACKEND_URL}/api/v1/languages/update`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}

export const getProjectRegionSaga = (page) => {
    return {
        type: actionTypes.GET_PROJECT_REGION_SAGA,
        page
    }
}
export const getStatues = () => {
    return {
        type: "STATUES_SAGA"
    }
}
export const getStatuesRed = (payload) => {
    return {
        type: "STATUES_RED",
        payload
    }
}
export const getUsers = () => {
    return {
        type: "USER_SAGA"
    }
}
export const getUserRed = (payload) => {
    return {
        type: "USER_RED",
        payload
    }
}
export const getRoles = () => {
    return {
        type: "ROLES_SAGA"
    }
}
export const AssignAction = (r, c) => {
    return {
        // eslint-disable-next-line no-unused-expressions
        type: "ASSIGN_SAGA",
        r,
        c
    }
}
export const getRolesRed = (payload) => {
    return {
        type: "ROLES_RED",
        payload
    }
}
export const getTasks = (r, c) => {
    return {
        // eslint-disable-next-line no-unused-expressions
        type: "TASKS_SAGA",
        r,
        c
    }
}
export const getTasksRed = (payload) => {
    return {
        type: "TASKS_RED",
        payload
    }
}
export const getNextProjectRegionSaga = (url) => {
    return {
        type: actionTypes.GET_NEXT_PROJECT_REGION_SAGA,
        url
    }
}
export const getProjectRegionReducer = (payload) => {
    return {
        type: actionTypes.GET_PROJECT_REGION_REDUCER,
        payload
    }
}
export const addRegionSaga = (region) => ({
    type: actionTypes.ADD_REGION_SAGA,
    payload: region
})

export const addRegionReducer = (region) => ({
    type: actionTypes.ADD_REGION_REDUCER,
    payload: region
})
export const deleteRegionSaga = ({ id, currentPage }) => ({
    type: actionTypes.DELETE_REGION_SAGA,
    id,
    currentPage
})

export const deleteRegionReducer = (id) => ({
    type: actionTypes.DELETE_REGION_REDUCER,
    id
})
export const updateRegionSaga = (region) => ({
    type: actionTypes.UPDATE_REGION_SAGA,
    region
})

export const updateRegionReducer = (region) => ({
    type: actionTypes.UPDATE_REGION_REDUCER,
    region
})

export const _login = ({ mobile_phone, password }, callback, callbackErr) => {
    API.post('/api/v1/auth/login', { mobile_phone, password })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}
//************************************//
export const _loginTwoFactor = ({ email, code }, callback, callbackErr) => {
    API.post('/login-2fa', { email, code })
        .then(function (res) {
            callback(res)
        })
        .catch(function ({ data }) {
            callbackErr(data)
        })
}
//************************************//
export const _register = ({ id_token, password, role_name, username }, callback, callbackErr) => {
    return dispatch => {
        API.post(`/api/v1/auth/signup`, { id_token, password, role_name, username })
            .then(function ({ data }) {
                callback(data)
            })
            .catch(function () {
                callbackErr()
            })
    }
}
//************************************//
export const _setPin = ({ mobile_phone, password, pin }, callback, callbackErr) => {
    API.post(`/api/v1/users/set_pin`, { mobile_phone, password, pin })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function () {
            callbackErr()
        })
}
//************************************//
export const _checkPin = (pin, callback, callbackErr) => {
    API.post(`/api/v1/users/check_pin`, { pin })
        .then(function (data) {
            callback(data)
        })
        .catch(function (e) {
            callbackErr()
        })
}
//************************************//
export const _forgetPassword = (data, callback, callbackErr) => {
    API.post('/api/v1/auth/resetPasswordUsingOTP', data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
            callbackErr(data.data)
        })
}
//************************************//
export const _resetPassword = ({ email, currentPassword, newPassword }, callback, callbackErr) => {
    API.post('/reset-password', { email, current_password: currentPassword, new_password: newPassword })
        .then(function (res) {
            callback(res)
        })
        .catch(function ({ data }) {
            callbackErr(data.data)
        })
}
//************************************//
export const _changePassword = (data, callback, callbackErr) => {
    API.post('/user/account/change-password', { ...data })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
            callbackErr(data.data)
        })
}
//************************************//
export const _checkNumberExist = (id_token, callback, callbackErr) => {
    API.post('/api/v1/users/check_existing_phone', { id_token })
        .then(function (res) {
            callback(res)
        })
        .catch(function ({ data }) {
            callbackErr(data)
        })
}
//************************************//


//************************************//

//************************************//
export const _getMyProfile = (dispatch) => {
    API.get('/user/account/my-profile')
        .then(function ({ data }) {
            const userToken = JSON.parse(sessionStorage.getItem('USER_TOKEN'))
            userToken.user = { ...userToken.user, ...data.basic_info }
            sessionStorage.setItem("USER_TOKEN", JSON.stringify(userToken))
            dispatch({ type: 'USER_MY_PROFILE', data })
        })
        .catch(function (res) { })
}
//************************************//
export const _changeSecurityConfig = (data, callback, callbackErr) => {
    API.post('/api/v1/micro_configs/update', data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}
//************************************//
export const _getSecurityConfig = (callback, callbackErr) => {
    API.get('/api/v1/micro_configs/show')
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}
//************************************//
export const _getAutoLoginInfo = (callback, callbackErr) => {
    API.get('/api/v1/micro_configs/show', { baseURL:`${process.env.REACT_APP_LOG_BACKEND_URL}` })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}
//************************************//
export const _changeAutoLoginInfo = (data, callback, callbackErr) => {
    API.post('/api/v1/micro_configs/update', data, { baseURL: `${process.env.REACT_APP_LOG_BACKEND_URL}` })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}
//************************************//
export const _changeGeneralInfo = (data, callback, callbackErr) => {
    return dispatch => {
        API.post('/user/account/change-general-info', { ...data })
            .then(function ({ data }) {
                _getMyProfile(dispatch)
            })
            .catch(function ({ data }) {
                callbackErr(data.data)
            })
    }

}
//************************************//
export const _getRoleList = ({ limit, page, sort, filter }, callback) => {
    API.get('/api/v1/roles', {
        params: {
            limit,
            page,
            sort,
            filter,
            datatables: 1
        }
    })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) { })
}
//************************************//
export const _deleteRole = (id, callback) => {
    API.post(`/api/v1/roles/destroy`, { id })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (data) {
        })
}
//************************************//
export const _getUserList = ({ limit, page, sort, filter }, callback) => {
    API.get('/api/v1/users', {
        params: {
            limit,
            page,
            sort,
            filter,
            datatables: true,
            users_filter: []
        }
    })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) { })
}
//************************************//
export const _getBlockingIPsList = ({ limit, page, sort, filter }, callback) => {
    API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/blocked_ips`, {
        params: {
            limit,
            page,
            sort,
            filter,
            datatables: true
        }
    })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) { })
}
export const _getBlockingCountryList = ({ limit, page, sort, filter }, callback) => {
    API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/blocked_countries`, {
        params: {
            limit,
            page,
            sort,
            filter,
            datatables: true
        }
    })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) { })
}
export const _getBlockingSubnetList = ({ limit, page, sort, filter }, callback) => {
    API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/blocked_subnet`, {
        params: {
            limit,
            page,
            sort,
            filter,
            datatables: true
        }
    })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) { })
}
//************************************//
export const _deleteIP = (id, callback) => {
    API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/blocked_ips/destroy`, { id })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
        })
}
export const _deleteCountry = (id, callback) => {
    API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/blocked_countries/destroy/${id}`, { id })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
        })
}
export const _deleteSubnet = (id, callback) => {
    API.get(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/blocked_subnet/destroy/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
        })
}
//************************************//
export const _deleteUser = (id, callback) => {
    API.post(`/api/v1/users/delete`, { id })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
        })
}
//************************************//
export const _invite = ({ firstName, lastName, email }, callback, callbackErr) => {
    API.post('/user/invite', { first_name: firstName, last_name: lastName, email })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
            callbackErr(data.data)
        })
}
//************************************//
export const _resendInvite = (id) => {
    API.post(`/user/${id}/resend-invite`)
        .then(function ({ data }) {
        })
        .catch(function ({ data }) {
        })
}
//************************************//
export const _addIP = (data, callback, callbackErr) => {
    API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/blocked_ips/create`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}
export const _addCountry = (data, callback, callbackErr) => {
    API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/blocked_countries/create`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}
export const _addSubnet = (data, callback, callbackErr) => {
    API.post(`${process.env.REACT_APP_USER_BASE_URL}/api/v1/blocked_subnet/create`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}
//************************************//
export const _addUser = (data, callback, callbackErr) => {
    API.post(`/api/v1/users/create`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}
//************************************//
export const _editUserInfo = ({ id, username, roles }, callback, callbackErr) => {
    API.post(`/api/v1/users/update`, { id, username, roles })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}
//************************************//
export const _addRole = (data, callback, callbackErr) => {
    API.post('/api/v1/roles/create', data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}
//************************************//
export const _editRole = (data, callback, callbackErr) => {
    API.post(`/api/v1/roles/update`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}
//************************************//
export const _getPermissions = ({ }, callback) => {
    API.get('/user/role/get-permissions', {
        params: {}
    })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) { })
}
//************************************//
export const _updatePermissions = (permissions, callback) => {
    API.post('/user/role/update-permissions', { permissions })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function () { })
}
//************************************//
export const _updateUserRole = ({ id, roles }, callback) => {
    API.post(`/user/${id}/update-roles`, { roles })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//************************************//
export const _searchRole = async (q = '') => {
    const res = await API.get('/api/v1/roles')
    const result = _.map(res.data, (v, k) => {
        return { value: v.id, label: v.role_translations.name }
    })
    return result
}
//************************************//
export const _updateUserStatus = (data, callback) => {
    API.post(`/user/${data.userId}/change-status`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
export const addCategorySaga = (category) => {
    console.log('action')
    return ({
        type: actionTypes.ADD_CATEGORY_SAGA,
        payload: category
    })
}

export const addCategoryReducer = (category) => ({
    type: actionTypes.ADD_CATEGORY_REDUCER,
    payload: category
})
export const getCategoriesSaga = pagelink => ({
    type: actionTypes.GET_CATEGORIES_SAGA,
    pagelink
})
export const updateCategorySaga = (category) => ({
    type: actionTypes.UPDATE_CATEGORY_SAGA,
    category
})

export const updateCategoryReducer = (category) => ({
    type: actionTypes.UPDATE_CATEGORY_REDUCER,
    category
})
export const getCategoriesReducer = (categories) => ({
    type: actionTypes.GET_CATEGORIES_REDUCER,
    payload: categories
})
export const getCategoriesDataSaga = () => ({
    type: actionTypes.GET_CATEGORIES_DATA_SAGA
})

export const getCategoriesDataReducer = (data) => ({
    type: actionTypes.GET_CATEGORIES_DATA_REDUCER,
    data
})
export const deleteCategorySaga = (id) => ({
    type: actionTypes.DELETE_CATEGORY_SAGA,
    id
})

export const deleteCategoryReducer = (id) => ({
    type: actionTypes.DELETE_CATEGORY_REDUCER,
    id
})
export const loaded = () => {

    return {

        type: actionTypes.DONE

    }

}
export const loading = () => {

    return {

        type: actionTypes.LOADING

    }

}


export const showConfirmationMsg = (confirmInfoPayload) => {

    return {
        type: actionTypes.SHOW_CONFIRM_MSG,
        payload: confirmInfoPayload
    }
}
//***************************************************************//
export const _getLanguages = async () => {
    const languages = store.getState().settings.languages
    if (!languages.length > 0) {
        const res = await API.get(`${process.env.REACT_APP_CATEGORIES_CONTENT_BACKEND_URL}/api/v1/languages/index`)
        const result = _.map(res.data, (v, k) => {
            return {value: v.language_code, label: v.name}
        })
        store.dispatch({
            type: "languages_get",
            data: res.data
        })
        return result
    } else {
        const result = _.map(languages, (v, k) => {
            return {value: v.language_code, label: v.name}
        })
        return result
    }
}
export const getRegionTypes = async () => {
    const res = await API.get(actionTypes.REGIONS_Type_URL)
    const result = _.map(res.data, (v, k) => {
        return { value: v.id, label: v.translations[0].region_type_name }
    })
    store.dispatch({
        type: "region_type_data",
        data: res.data
    })
    return result
}
export const _getMobileBugsList = ({ limit, page, sort, filter }, callback) => {
    API.get(`${process.env.REACT_APP_LOG_BACKEND_URL}/api/v1/bugs`, {
        params: {
            limit,
            page,
            sort,
            filter,
            datatables: true
        }
    })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (res) {
        })
}