import React from "react"
import {Circle, HelpCircle, User} from "react-feather"

export const Navigation = [
    {
        id: "36",
        title: "dashboard.service-shipping.nav.myShippingOrders",
        icon: <User color={'#fff'} size={20}/>,
        children: [
                    {
                        id: 'my-orders',
                        title: 'dashboard.service-shipping.nav.my-orders',
                        icon: <Circle size={12} />,
                        navLink: '/orders/my-orders',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    },
                    {
                        id: 'my-orders-requests',
                        title: 'dashboard.service-shipping.nav.my-orders-requests',
                        icon: <Circle size={12} />,
                        navLink: '/orders/my-orders-requests',
                        action: 'call',
                        resource: 'NoPermissionCode'
                    }
                ]
    }
]
